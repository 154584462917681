/* hide tauchart's ui for export options */
.tau-chart__export {
  display: none;
}

/* react splitpane resizer */
.Resizer.vertical:hover {
  border-left: 5px solid var(--primary-color-90);
  border-right: 5px solid var(--primary-color-90);
}

/* react splitpane resizer */
.Resizer.horizontal:hover {
  border-top: 5px solid var(--primary-color-90);
  border-bottom: 5px solid var(--primary-color-90);
}

.Resizer:hover {
  transition: all 0.3s ease;
}

/* QueryResultDataTable react-window/react-draggable implementaion */
.DragHandle {
  flex: 0 0 16px;
  z-index: 2;
  cursor: col-resize;
  color: rgba(0, 0, 0, 0.2);
}
.DragHandle:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.DragHandleActive,
.DragHandleActive:hover {
  color: rgba(0, 0, 0, 0.6);
  z-index: 3;
}

.DragHandleIcon {
  flex: 0 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Missing Ace diff editor style for highlighting code changes */
.codeMarker {
  background: #fff677;
  position: absolute;
  z-index: 20;
}
